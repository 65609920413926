/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { InlineResponse2006 } from '../models';
// @ts-ignore
import { Tag } from '../models';
// @ts-ignore
import { TagJsonld } from '../models';
/**
 * TagApi - axios parameter creator
 * @export
 */
export const TagApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes the Tag resource.
         * @summary Removes the Tag resource.
         * @param {string} tagId Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTagItem: async (tagId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('deleteTagItem', 'tagId', tagId)
            const localVarPath = `/api/tags/{tagId}`
                .replace(`{${"tagId"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Tag resources.
         * @summary Retrieves the collection of Tag resources.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagCollection: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Tag resource.
         * @summary Retrieves a Tag resource.
         * @param {string} tagId Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagItem: async (tagId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('getTagItem', 'tagId', tagId)
            const localVarPath = `/api/tags/{tagId}`
                .replace(`{${"tagId"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Tag resource.
         * @summary Updates the Tag resource.
         * @param {string} tagId Resource identifier
         * @param {Tag} tag The updated Tag resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchTagItem: async (tagId: string, tag: Tag, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('patchTagItem', 'tagId', tagId)
            // verify required parameter 'tag' is not null or undefined
            assertParamExists('patchTagItem', 'tag', tag)
            const localVarPath = `/api/tags/{tagId}`
                .replace(`{${"tagId"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tag, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Tag resource.
         * @summary Creates a Tag resource.
         * @param {TagJsonld} tagJsonld The new Tag resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTagCollection: async (tagJsonld: TagJsonld, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagJsonld' is not null or undefined
            assertParamExists('postTagCollection', 'tagJsonld', tagJsonld)
            const localVarPath = `/api/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tagJsonld, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Tag resource.
         * @summary Replaces the Tag resource.
         * @param {string} tagId Resource identifier
         * @param {TagJsonld} tagJsonld The updated Tag resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTagItem: async (tagId: string, tagJsonld: TagJsonld, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('putTagItem', 'tagId', tagId)
            // verify required parameter 'tagJsonld' is not null or undefined
            assertParamExists('putTagItem', 'tagJsonld', tagJsonld)
            const localVarPath = `/api/tags/{tagId}`
                .replace(`{${"tagId"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tagJsonld, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TagApi - functional programming interface
 * @export
 */
export const TagApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TagApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes the Tag resource.
         * @summary Removes the Tag resource.
         * @param {string} tagId Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTagItem(tagId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTagItem(tagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of Tag resources.
         * @summary Retrieves the collection of Tag resources.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTagCollection(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2006>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTagCollection(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Tag resource.
         * @summary Retrieves a Tag resource.
         * @param {string} tagId Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTagItem(tagId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTagItem(tagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Tag resource.
         * @summary Updates the Tag resource.
         * @param {string} tagId Resource identifier
         * @param {Tag} tag The updated Tag resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchTagItem(tagId: string, tag: Tag, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchTagItem(tagId, tag, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Tag resource.
         * @summary Creates a Tag resource.
         * @param {TagJsonld} tagJsonld The new Tag resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postTagCollection(tagJsonld: TagJsonld, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postTagCollection(tagJsonld, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Tag resource.
         * @summary Replaces the Tag resource.
         * @param {string} tagId Resource identifier
         * @param {TagJsonld} tagJsonld The updated Tag resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putTagItem(tagId: string, tagJsonld: TagJsonld, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putTagItem(tagId, tagJsonld, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TagApi - factory interface
 * @export
 */
export const TagApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TagApiFp(configuration)
    return {
        /**
         * Removes the Tag resource.
         * @summary Removes the Tag resource.
         * @param {string} tagId Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTagItem(tagId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTagItem(tagId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Tag resources.
         * @summary Retrieves the collection of Tag resources.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagCollection(options?: any): AxiosPromise<InlineResponse2006> {
            return localVarFp.getTagCollection(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Tag resource.
         * @summary Retrieves a Tag resource.
         * @param {string} tagId Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagItem(tagId: string, options?: any): AxiosPromise<TagJsonld> {
            return localVarFp.getTagItem(tagId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the Tag resource.
         * @summary Updates the Tag resource.
         * @param {string} tagId Resource identifier
         * @param {Tag} tag The updated Tag resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchTagItem(tagId: string, tag: Tag, options?: any): AxiosPromise<TagJsonld> {
            return localVarFp.patchTagItem(tagId, tag, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Tag resource.
         * @summary Creates a Tag resource.
         * @param {TagJsonld} tagJsonld The new Tag resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTagCollection(tagJsonld: TagJsonld, options?: any): AxiosPromise<TagJsonld> {
            return localVarFp.postTagCollection(tagJsonld, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Tag resource.
         * @summary Replaces the Tag resource.
         * @param {string} tagId Resource identifier
         * @param {TagJsonld} tagJsonld The updated Tag resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTagItem(tagId: string, tagJsonld: TagJsonld, options?: any): AxiosPromise<TagJsonld> {
            return localVarFp.putTagItem(tagId, tagJsonld, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TagApi - object-oriented interface
 * @export
 * @class TagApi
 * @extends {BaseAPI}
 */
export class TagApi extends BaseAPI {
    /**
     * Removes the Tag resource.
     * @summary Removes the Tag resource.
     * @param {string} tagId Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public deleteTagItem(tagId: string, options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).deleteTagItem(tagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Tag resources.
     * @summary Retrieves the collection of Tag resources.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public getTagCollection(options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).getTagCollection(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Tag resource.
     * @summary Retrieves a Tag resource.
     * @param {string} tagId Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public getTagItem(tagId: string, options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).getTagItem(tagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Tag resource.
     * @summary Updates the Tag resource.
     * @param {string} tagId Resource identifier
     * @param {Tag} tag The updated Tag resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public patchTagItem(tagId: string, tag: Tag, options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).patchTagItem(tagId, tag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Tag resource.
     * @summary Creates a Tag resource.
     * @param {TagJsonld} tagJsonld The new Tag resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public postTagCollection(tagJsonld: TagJsonld, options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).postTagCollection(tagJsonld, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Tag resource.
     * @summary Replaces the Tag resource.
     * @param {string} tagId Resource identifier
     * @param {TagJsonld} tagJsonld The updated Tag resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public putTagItem(tagId: string, tagJsonld: TagJsonld, options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).putTagItem(tagId, tagJsonld, options).then((request) => request(this.axios, this.basePath));
    }
}
