/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { InlineResponse2007 } from '../models';
// @ts-ignore
import { UserRecipeFavourites } from '../models';
// @ts-ignore
import { UserRecipeFavouritesJsonld } from '../models';
/**
 * UserRecipeFavouritesApi - axios parameter creator
 * @export
 */
export const UserRecipeFavouritesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes the UserRecipeFavourites resource.
         * @summary Removes the UserRecipeFavourites resource.
         * @param {string} userRecipeFavouriteId Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserRecipeFavouritesItem: async (userRecipeFavouriteId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userRecipeFavouriteId' is not null or undefined
            assertParamExists('deleteUserRecipeFavouritesItem', 'userRecipeFavouriteId', userRecipeFavouriteId)
            const localVarPath = `/api/user_recipe_favourites/{userRecipeFavouriteId}`
                .replace(`{${"userRecipeFavouriteId"}}`, encodeURIComponent(String(userRecipeFavouriteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of UserRecipeFavourites resources.
         * @summary Retrieves the collection of UserRecipeFavourites resources.
         * @param {string} [user] 
         * @param {Array<string>} [user2] 
         * @param {string} [recipe] 
         * @param {Array<string>} [recipe2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRecipeFavouritesCollection: async (user?: string, user2?: Array<string>, recipe?: string, recipe2?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user_recipe_favourites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (user !== undefined) {
                localVarQueryParameter['user'] = user;
            }

            if (user2) {
                localVarQueryParameter['user[]'] = user2;
            }

            if (recipe !== undefined) {
                localVarQueryParameter['recipe'] = recipe;
            }

            if (recipe2) {
                localVarQueryParameter['recipe[]'] = recipe2;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a UserRecipeFavourites resource.
         * @summary Retrieves a UserRecipeFavourites resource.
         * @param {string} userRecipeFavouriteId Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRecipeFavouritesItem: async (userRecipeFavouriteId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userRecipeFavouriteId' is not null or undefined
            assertParamExists('getUserRecipeFavouritesItem', 'userRecipeFavouriteId', userRecipeFavouriteId)
            const localVarPath = `/api/user_recipe_favourites/{userRecipeFavouriteId}`
                .replace(`{${"userRecipeFavouriteId"}}`, encodeURIComponent(String(userRecipeFavouriteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a UserRecipeFavourites resource.
         * @summary Creates a UserRecipeFavourites resource.
         * @param {UserRecipeFavouritesJsonld} userRecipeFavouritesJsonld The new UserRecipeFavourites resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserRecipeFavouritesCollection: async (userRecipeFavouritesJsonld: UserRecipeFavouritesJsonld, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userRecipeFavouritesJsonld' is not null or undefined
            assertParamExists('postUserRecipeFavouritesCollection', 'userRecipeFavouritesJsonld', userRecipeFavouritesJsonld)
            const localVarPath = `/api/user_recipe_favourites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRecipeFavouritesJsonld, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserRecipeFavouritesApi - functional programming interface
 * @export
 */
export const UserRecipeFavouritesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserRecipeFavouritesApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes the UserRecipeFavourites resource.
         * @summary Removes the UserRecipeFavourites resource.
         * @param {string} userRecipeFavouriteId Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserRecipeFavouritesItem(userRecipeFavouriteId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserRecipeFavouritesItem(userRecipeFavouriteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of UserRecipeFavourites resources.
         * @summary Retrieves the collection of UserRecipeFavourites resources.
         * @param {string} [user] 
         * @param {Array<string>} [user2] 
         * @param {string} [recipe] 
         * @param {Array<string>} [recipe2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserRecipeFavouritesCollection(user?: string, user2?: Array<string>, recipe?: string, recipe2?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2007>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserRecipeFavouritesCollection(user, user2, recipe, recipe2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a UserRecipeFavourites resource.
         * @summary Retrieves a UserRecipeFavourites resource.
         * @param {string} userRecipeFavouriteId Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserRecipeFavouritesItem(userRecipeFavouriteId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRecipeFavouritesJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserRecipeFavouritesItem(userRecipeFavouriteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a UserRecipeFavourites resource.
         * @summary Creates a UserRecipeFavourites resource.
         * @param {UserRecipeFavouritesJsonld} userRecipeFavouritesJsonld The new UserRecipeFavourites resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUserRecipeFavouritesCollection(userRecipeFavouritesJsonld: UserRecipeFavouritesJsonld, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRecipeFavouritesJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUserRecipeFavouritesCollection(userRecipeFavouritesJsonld, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserRecipeFavouritesApi - factory interface
 * @export
 */
export const UserRecipeFavouritesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserRecipeFavouritesApiFp(configuration)
    return {
        /**
         * Removes the UserRecipeFavourites resource.
         * @summary Removes the UserRecipeFavourites resource.
         * @param {string} userRecipeFavouriteId Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserRecipeFavouritesItem(userRecipeFavouriteId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUserRecipeFavouritesItem(userRecipeFavouriteId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of UserRecipeFavourites resources.
         * @summary Retrieves the collection of UserRecipeFavourites resources.
         * @param {string} [user] 
         * @param {Array<string>} [user2] 
         * @param {string} [recipe] 
         * @param {Array<string>} [recipe2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRecipeFavouritesCollection(user?: string, user2?: Array<string>, recipe?: string, recipe2?: Array<string>, options?: any): AxiosPromise<InlineResponse2007> {
            return localVarFp.getUserRecipeFavouritesCollection(user, user2, recipe, recipe2, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a UserRecipeFavourites resource.
         * @summary Retrieves a UserRecipeFavourites resource.
         * @param {string} userRecipeFavouriteId Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRecipeFavouritesItem(userRecipeFavouriteId: string, options?: any): AxiosPromise<UserRecipeFavouritesJsonld> {
            return localVarFp.getUserRecipeFavouritesItem(userRecipeFavouriteId, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a UserRecipeFavourites resource.
         * @summary Creates a UserRecipeFavourites resource.
         * @param {UserRecipeFavouritesJsonld} userRecipeFavouritesJsonld The new UserRecipeFavourites resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserRecipeFavouritesCollection(userRecipeFavouritesJsonld: UserRecipeFavouritesJsonld, options?: any): AxiosPromise<UserRecipeFavouritesJsonld> {
            return localVarFp.postUserRecipeFavouritesCollection(userRecipeFavouritesJsonld, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserRecipeFavouritesApi - object-oriented interface
 * @export
 * @class UserRecipeFavouritesApi
 * @extends {BaseAPI}
 */
export class UserRecipeFavouritesApi extends BaseAPI {
    /**
     * Removes the UserRecipeFavourites resource.
     * @summary Removes the UserRecipeFavourites resource.
     * @param {string} userRecipeFavouriteId Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRecipeFavouritesApi
     */
    public deleteUserRecipeFavouritesItem(userRecipeFavouriteId: string, options?: AxiosRequestConfig) {
        return UserRecipeFavouritesApiFp(this.configuration).deleteUserRecipeFavouritesItem(userRecipeFavouriteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of UserRecipeFavourites resources.
     * @summary Retrieves the collection of UserRecipeFavourites resources.
     * @param {string} [user] 
     * @param {Array<string>} [user2] 
     * @param {string} [recipe] 
     * @param {Array<string>} [recipe2] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRecipeFavouritesApi
     */
    public getUserRecipeFavouritesCollection(user?: string, user2?: Array<string>, recipe?: string, recipe2?: Array<string>, options?: AxiosRequestConfig) {
        return UserRecipeFavouritesApiFp(this.configuration).getUserRecipeFavouritesCollection(user, user2, recipe, recipe2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a UserRecipeFavourites resource.
     * @summary Retrieves a UserRecipeFavourites resource.
     * @param {string} userRecipeFavouriteId Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRecipeFavouritesApi
     */
    public getUserRecipeFavouritesItem(userRecipeFavouriteId: string, options?: AxiosRequestConfig) {
        return UserRecipeFavouritesApiFp(this.configuration).getUserRecipeFavouritesItem(userRecipeFavouriteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a UserRecipeFavourites resource.
     * @summary Creates a UserRecipeFavourites resource.
     * @param {UserRecipeFavouritesJsonld} userRecipeFavouritesJsonld The new UserRecipeFavourites resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRecipeFavouritesApi
     */
    public postUserRecipeFavouritesCollection(userRecipeFavouritesJsonld: UserRecipeFavouritesJsonld, options?: AxiosRequestConfig) {
        return UserRecipeFavouritesApiFp(this.configuration).postUserRecipeFavouritesCollection(userRecipeFavouritesJsonld, options).then((request) => request(this.axios, this.basePath));
    }
}
