/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { InlineResponse2005 } from '../models';
// @ts-ignore
import { ShoppingItem } from '../models';
// @ts-ignore
import { ShoppingItemJsonld } from '../models';
/**
 * ShoppingItemApi - axios parameter creator
 * @export
 */
export const ShoppingItemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes the ShoppingItem resource.
         * @summary Removes the ShoppingItem resource.
         * @param {string} shoppingItemId Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteShoppingItemItem: async (shoppingItemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shoppingItemId' is not null or undefined
            assertParamExists('deleteShoppingItemItem', 'shoppingItemId', shoppingItemId)
            const localVarPath = `/api/shopping_items/{shoppingItemId}`
                .replace(`{${"shoppingItemId"}}`, encodeURIComponent(String(shoppingItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of ShoppingItem resources.
         * @summary Retrieves the collection of ShoppingItem resources.
         * @param {string} [user] 
         * @param {Array<string>} [user2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShoppingItemCollection: async (user?: string, user2?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shopping_items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (user !== undefined) {
                localVarQueryParameter['user'] = user;
            }

            if (user2) {
                localVarQueryParameter['user[]'] = user2;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a ShoppingItem resource.
         * @summary Retrieves a ShoppingItem resource.
         * @param {string} shoppingItemId Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShoppingItemItem: async (shoppingItemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shoppingItemId' is not null or undefined
            assertParamExists('getShoppingItemItem', 'shoppingItemId', shoppingItemId)
            const localVarPath = `/api/shopping_items/{shoppingItemId}`
                .replace(`{${"shoppingItemId"}}`, encodeURIComponent(String(shoppingItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the ShoppingItem resource.
         * @summary Updates the ShoppingItem resource.
         * @param {string} shoppingItemId Resource identifier
         * @param {ShoppingItem} shoppingItem The updated ShoppingItem resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchShoppingItemItem: async (shoppingItemId: string, shoppingItem: ShoppingItem, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shoppingItemId' is not null or undefined
            assertParamExists('patchShoppingItemItem', 'shoppingItemId', shoppingItemId)
            // verify required parameter 'shoppingItem' is not null or undefined
            assertParamExists('patchShoppingItemItem', 'shoppingItem', shoppingItem)
            const localVarPath = `/api/shopping_items/{shoppingItemId}`
                .replace(`{${"shoppingItemId"}}`, encodeURIComponent(String(shoppingItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shoppingItem, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a ShoppingItem resource.
         * @summary Creates a ShoppingItem resource.
         * @param {ShoppingItemJsonld} shoppingItemJsonld The new ShoppingItem resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postShoppingItemCollection: async (shoppingItemJsonld: ShoppingItemJsonld, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shoppingItemJsonld' is not null or undefined
            assertParamExists('postShoppingItemCollection', 'shoppingItemJsonld', shoppingItemJsonld)
            const localVarPath = `/api/shopping_items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shoppingItemJsonld, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the ShoppingItem resource.
         * @summary Replaces the ShoppingItem resource.
         * @param {string} shoppingItemId Resource identifier
         * @param {ShoppingItemJsonld} shoppingItemJsonld The updated ShoppingItem resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putShoppingItemItem: async (shoppingItemId: string, shoppingItemJsonld: ShoppingItemJsonld, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shoppingItemId' is not null or undefined
            assertParamExists('putShoppingItemItem', 'shoppingItemId', shoppingItemId)
            // verify required parameter 'shoppingItemJsonld' is not null or undefined
            assertParamExists('putShoppingItemItem', 'shoppingItemJsonld', shoppingItemJsonld)
            const localVarPath = `/api/shopping_items/{shoppingItemId}`
                .replace(`{${"shoppingItemId"}}`, encodeURIComponent(String(shoppingItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shoppingItemJsonld, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShoppingItemApi - functional programming interface
 * @export
 */
export const ShoppingItemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShoppingItemApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes the ShoppingItem resource.
         * @summary Removes the ShoppingItem resource.
         * @param {string} shoppingItemId Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteShoppingItemItem(shoppingItemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteShoppingItemItem(shoppingItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of ShoppingItem resources.
         * @summary Retrieves the collection of ShoppingItem resources.
         * @param {string} [user] 
         * @param {Array<string>} [user2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShoppingItemCollection(user?: string, user2?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2005>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getShoppingItemCollection(user, user2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a ShoppingItem resource.
         * @summary Retrieves a ShoppingItem resource.
         * @param {string} shoppingItemId Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShoppingItemItem(shoppingItemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShoppingItemJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getShoppingItemItem(shoppingItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the ShoppingItem resource.
         * @summary Updates the ShoppingItem resource.
         * @param {string} shoppingItemId Resource identifier
         * @param {ShoppingItem} shoppingItem The updated ShoppingItem resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchShoppingItemItem(shoppingItemId: string, shoppingItem: ShoppingItem, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShoppingItemJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchShoppingItemItem(shoppingItemId, shoppingItem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a ShoppingItem resource.
         * @summary Creates a ShoppingItem resource.
         * @param {ShoppingItemJsonld} shoppingItemJsonld The new ShoppingItem resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postShoppingItemCollection(shoppingItemJsonld: ShoppingItemJsonld, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShoppingItemJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postShoppingItemCollection(shoppingItemJsonld, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the ShoppingItem resource.
         * @summary Replaces the ShoppingItem resource.
         * @param {string} shoppingItemId Resource identifier
         * @param {ShoppingItemJsonld} shoppingItemJsonld The updated ShoppingItem resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putShoppingItemItem(shoppingItemId: string, shoppingItemJsonld: ShoppingItemJsonld, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShoppingItemJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putShoppingItemItem(shoppingItemId, shoppingItemJsonld, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShoppingItemApi - factory interface
 * @export
 */
export const ShoppingItemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShoppingItemApiFp(configuration)
    return {
        /**
         * Removes the ShoppingItem resource.
         * @summary Removes the ShoppingItem resource.
         * @param {string} shoppingItemId Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteShoppingItemItem(shoppingItemId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteShoppingItemItem(shoppingItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of ShoppingItem resources.
         * @summary Retrieves the collection of ShoppingItem resources.
         * @param {string} [user] 
         * @param {Array<string>} [user2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShoppingItemCollection(user?: string, user2?: Array<string>, options?: any): AxiosPromise<InlineResponse2005> {
            return localVarFp.getShoppingItemCollection(user, user2, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a ShoppingItem resource.
         * @summary Retrieves a ShoppingItem resource.
         * @param {string} shoppingItemId Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShoppingItemItem(shoppingItemId: string, options?: any): AxiosPromise<ShoppingItemJsonld> {
            return localVarFp.getShoppingItemItem(shoppingItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the ShoppingItem resource.
         * @summary Updates the ShoppingItem resource.
         * @param {string} shoppingItemId Resource identifier
         * @param {ShoppingItem} shoppingItem The updated ShoppingItem resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchShoppingItemItem(shoppingItemId: string, shoppingItem: ShoppingItem, options?: any): AxiosPromise<ShoppingItemJsonld> {
            return localVarFp.patchShoppingItemItem(shoppingItemId, shoppingItem, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a ShoppingItem resource.
         * @summary Creates a ShoppingItem resource.
         * @param {ShoppingItemJsonld} shoppingItemJsonld The new ShoppingItem resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postShoppingItemCollection(shoppingItemJsonld: ShoppingItemJsonld, options?: any): AxiosPromise<ShoppingItemJsonld> {
            return localVarFp.postShoppingItemCollection(shoppingItemJsonld, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the ShoppingItem resource.
         * @summary Replaces the ShoppingItem resource.
         * @param {string} shoppingItemId Resource identifier
         * @param {ShoppingItemJsonld} shoppingItemJsonld The updated ShoppingItem resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putShoppingItemItem(shoppingItemId: string, shoppingItemJsonld: ShoppingItemJsonld, options?: any): AxiosPromise<ShoppingItemJsonld> {
            return localVarFp.putShoppingItemItem(shoppingItemId, shoppingItemJsonld, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShoppingItemApi - object-oriented interface
 * @export
 * @class ShoppingItemApi
 * @extends {BaseAPI}
 */
export class ShoppingItemApi extends BaseAPI {
    /**
     * Removes the ShoppingItem resource.
     * @summary Removes the ShoppingItem resource.
     * @param {string} shoppingItemId Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingItemApi
     */
    public deleteShoppingItemItem(shoppingItemId: string, options?: AxiosRequestConfig) {
        return ShoppingItemApiFp(this.configuration).deleteShoppingItemItem(shoppingItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of ShoppingItem resources.
     * @summary Retrieves the collection of ShoppingItem resources.
     * @param {string} [user] 
     * @param {Array<string>} [user2] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingItemApi
     */
    public getShoppingItemCollection(user?: string, user2?: Array<string>, options?: AxiosRequestConfig) {
        return ShoppingItemApiFp(this.configuration).getShoppingItemCollection(user, user2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a ShoppingItem resource.
     * @summary Retrieves a ShoppingItem resource.
     * @param {string} shoppingItemId Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingItemApi
     */
    public getShoppingItemItem(shoppingItemId: string, options?: AxiosRequestConfig) {
        return ShoppingItemApiFp(this.configuration).getShoppingItemItem(shoppingItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the ShoppingItem resource.
     * @summary Updates the ShoppingItem resource.
     * @param {string} shoppingItemId Resource identifier
     * @param {ShoppingItem} shoppingItem The updated ShoppingItem resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingItemApi
     */
    public patchShoppingItemItem(shoppingItemId: string, shoppingItem: ShoppingItem, options?: AxiosRequestConfig) {
        return ShoppingItemApiFp(this.configuration).patchShoppingItemItem(shoppingItemId, shoppingItem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a ShoppingItem resource.
     * @summary Creates a ShoppingItem resource.
     * @param {ShoppingItemJsonld} shoppingItemJsonld The new ShoppingItem resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingItemApi
     */
    public postShoppingItemCollection(shoppingItemJsonld: ShoppingItemJsonld, options?: AxiosRequestConfig) {
        return ShoppingItemApiFp(this.configuration).postShoppingItemCollection(shoppingItemJsonld, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the ShoppingItem resource.
     * @summary Replaces the ShoppingItem resource.
     * @param {string} shoppingItemId Resource identifier
     * @param {ShoppingItemJsonld} shoppingItemJsonld The updated ShoppingItem resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShoppingItemApi
     */
    public putShoppingItemItem(shoppingItemId: string, shoppingItemJsonld: ShoppingItemJsonld, options?: AxiosRequestConfig) {
        return ShoppingItemApiFp(this.configuration).putShoppingItemItem(shoppingItemId, shoppingItemJsonld, options).then((request) => request(this.axios, this.basePath));
    }
}
