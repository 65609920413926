/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Ingredient } from '../models';
// @ts-ignore
import { IngredientJsonld } from '../models';
// @ts-ignore
import { InlineResponse2002 } from '../models';
/**
 * IngredientApi - axios parameter creator
 * @export
 */
export const IngredientApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes the Ingredient resource.
         * @summary Removes the Ingredient resource.
         * @param {string} ingredientId Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIngredientItem: async (ingredientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ingredientId' is not null or undefined
            assertParamExists('deleteIngredientItem', 'ingredientId', ingredientId)
            const localVarPath = `/api/ingredients/{ingredientId}`
                .replace(`{${"ingredientId"}}`, encodeURIComponent(String(ingredientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Ingredient resources.
         * @summary Retrieves the collection of Ingredient resources.
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIngredientCollection: async (name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ingredients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Ingredient resource.
         * @summary Retrieves a Ingredient resource.
         * @param {string} ingredientId Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIngredientItem: async (ingredientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ingredientId' is not null or undefined
            assertParamExists('getIngredientItem', 'ingredientId', ingredientId)
            const localVarPath = `/api/ingredients/{ingredientId}`
                .replace(`{${"ingredientId"}}`, encodeURIComponent(String(ingredientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Ingredient resource.
         * @summary Updates the Ingredient resource.
         * @param {string} ingredientId Resource identifier
         * @param {Ingredient} ingredient The updated Ingredient resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchIngredientItem: async (ingredientId: string, ingredient: Ingredient, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ingredientId' is not null or undefined
            assertParamExists('patchIngredientItem', 'ingredientId', ingredientId)
            // verify required parameter 'ingredient' is not null or undefined
            assertParamExists('patchIngredientItem', 'ingredient', ingredient)
            const localVarPath = `/api/ingredients/{ingredientId}`
                .replace(`{${"ingredientId"}}`, encodeURIComponent(String(ingredientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ingredient, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Ingredient resource.
         * @summary Creates a Ingredient resource.
         * @param {IngredientJsonld} ingredientJsonld The new Ingredient resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postIngredientCollection: async (ingredientJsonld: IngredientJsonld, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ingredientJsonld' is not null or undefined
            assertParamExists('postIngredientCollection', 'ingredientJsonld', ingredientJsonld)
            const localVarPath = `/api/ingredients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ingredientJsonld, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Ingredient resource.
         * @summary Replaces the Ingredient resource.
         * @param {string} ingredientId Resource identifier
         * @param {IngredientJsonld} ingredientJsonld The updated Ingredient resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putIngredientItem: async (ingredientId: string, ingredientJsonld: IngredientJsonld, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ingredientId' is not null or undefined
            assertParamExists('putIngredientItem', 'ingredientId', ingredientId)
            // verify required parameter 'ingredientJsonld' is not null or undefined
            assertParamExists('putIngredientItem', 'ingredientJsonld', ingredientJsonld)
            const localVarPath = `/api/ingredients/{ingredientId}`
                .replace(`{${"ingredientId"}}`, encodeURIComponent(String(ingredientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ingredientJsonld, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IngredientApi - functional programming interface
 * @export
 */
export const IngredientApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IngredientApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes the Ingredient resource.
         * @summary Removes the Ingredient resource.
         * @param {string} ingredientId Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteIngredientItem(ingredientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteIngredientItem(ingredientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of Ingredient resources.
         * @summary Retrieves the collection of Ingredient resources.
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIngredientCollection(name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2002>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIngredientCollection(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Ingredient resource.
         * @summary Retrieves a Ingredient resource.
         * @param {string} ingredientId Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIngredientItem(ingredientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IngredientJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIngredientItem(ingredientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Ingredient resource.
         * @summary Updates the Ingredient resource.
         * @param {string} ingredientId Resource identifier
         * @param {Ingredient} ingredient The updated Ingredient resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchIngredientItem(ingredientId: string, ingredient: Ingredient, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IngredientJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchIngredientItem(ingredientId, ingredient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Ingredient resource.
         * @summary Creates a Ingredient resource.
         * @param {IngredientJsonld} ingredientJsonld The new Ingredient resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postIngredientCollection(ingredientJsonld: IngredientJsonld, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IngredientJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postIngredientCollection(ingredientJsonld, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Ingredient resource.
         * @summary Replaces the Ingredient resource.
         * @param {string} ingredientId Resource identifier
         * @param {IngredientJsonld} ingredientJsonld The updated Ingredient resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putIngredientItem(ingredientId: string, ingredientJsonld: IngredientJsonld, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IngredientJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putIngredientItem(ingredientId, ingredientJsonld, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IngredientApi - factory interface
 * @export
 */
export const IngredientApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IngredientApiFp(configuration)
    return {
        /**
         * Removes the Ingredient resource.
         * @summary Removes the Ingredient resource.
         * @param {string} ingredientId Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIngredientItem(ingredientId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteIngredientItem(ingredientId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Ingredient resources.
         * @summary Retrieves the collection of Ingredient resources.
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIngredientCollection(name?: string, options?: any): AxiosPromise<InlineResponse2002> {
            return localVarFp.getIngredientCollection(name, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Ingredient resource.
         * @summary Retrieves a Ingredient resource.
         * @param {string} ingredientId Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIngredientItem(ingredientId: string, options?: any): AxiosPromise<IngredientJsonld> {
            return localVarFp.getIngredientItem(ingredientId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the Ingredient resource.
         * @summary Updates the Ingredient resource.
         * @param {string} ingredientId Resource identifier
         * @param {Ingredient} ingredient The updated Ingredient resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchIngredientItem(ingredientId: string, ingredient: Ingredient, options?: any): AxiosPromise<IngredientJsonld> {
            return localVarFp.patchIngredientItem(ingredientId, ingredient, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Ingredient resource.
         * @summary Creates a Ingredient resource.
         * @param {IngredientJsonld} ingredientJsonld The new Ingredient resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postIngredientCollection(ingredientJsonld: IngredientJsonld, options?: any): AxiosPromise<IngredientJsonld> {
            return localVarFp.postIngredientCollection(ingredientJsonld, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Ingredient resource.
         * @summary Replaces the Ingredient resource.
         * @param {string} ingredientId Resource identifier
         * @param {IngredientJsonld} ingredientJsonld The updated Ingredient resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putIngredientItem(ingredientId: string, ingredientJsonld: IngredientJsonld, options?: any): AxiosPromise<IngredientJsonld> {
            return localVarFp.putIngredientItem(ingredientId, ingredientJsonld, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IngredientApi - object-oriented interface
 * @export
 * @class IngredientApi
 * @extends {BaseAPI}
 */
export class IngredientApi extends BaseAPI {
    /**
     * Removes the Ingredient resource.
     * @summary Removes the Ingredient resource.
     * @param {string} ingredientId Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngredientApi
     */
    public deleteIngredientItem(ingredientId: string, options?: AxiosRequestConfig) {
        return IngredientApiFp(this.configuration).deleteIngredientItem(ingredientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Ingredient resources.
     * @summary Retrieves the collection of Ingredient resources.
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngredientApi
     */
    public getIngredientCollection(name?: string, options?: AxiosRequestConfig) {
        return IngredientApiFp(this.configuration).getIngredientCollection(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Ingredient resource.
     * @summary Retrieves a Ingredient resource.
     * @param {string} ingredientId Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngredientApi
     */
    public getIngredientItem(ingredientId: string, options?: AxiosRequestConfig) {
        return IngredientApiFp(this.configuration).getIngredientItem(ingredientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Ingredient resource.
     * @summary Updates the Ingredient resource.
     * @param {string} ingredientId Resource identifier
     * @param {Ingredient} ingredient The updated Ingredient resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngredientApi
     */
    public patchIngredientItem(ingredientId: string, ingredient: Ingredient, options?: AxiosRequestConfig) {
        return IngredientApiFp(this.configuration).patchIngredientItem(ingredientId, ingredient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Ingredient resource.
     * @summary Creates a Ingredient resource.
     * @param {IngredientJsonld} ingredientJsonld The new Ingredient resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngredientApi
     */
    public postIngredientCollection(ingredientJsonld: IngredientJsonld, options?: AxiosRequestConfig) {
        return IngredientApiFp(this.configuration).postIngredientCollection(ingredientJsonld, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Ingredient resource.
     * @summary Replaces the Ingredient resource.
     * @param {string} ingredientId Resource identifier
     * @param {IngredientJsonld} ingredientJsonld The updated Ingredient resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngredientApi
     */
    public putIngredientItem(ingredientId: string, ingredientJsonld: IngredientJsonld, options?: AxiosRequestConfig) {
        return IngredientApiFp(this.configuration).putIngredientItem(ingredientId, ingredientJsonld, options).then((request) => request(this.axios, this.basePath));
    }
}
